@tailwind base;
@tailwind components;
@tailwind utilities;

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #fff;
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.1);
  color: black;
  width: fit-content;
  border-radius: 15px;
  padding: 0px 10px 0px 10px;
  appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
  text-align: center;
  font-size: 20px;
  border: none;
  padding-right: 14px;
  padding-left: 14px;
  color: black;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
